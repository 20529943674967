import { THEME_CONFIG } from "~config/THEME_CONFIG.config";

const { grid } = THEME_CONFIG;
const { gridFullWidth, gridMinWidth } = grid;

export function clampBuilder(minFontSize: number, maxFontSize: number) {
  const slope = (maxFontSize - minFontSize) / (gridFullWidth - gridMinWidth);
  const yAxisIntersection = -gridMinWidth * slope + minFontSize;

  return `clamp( ${minFontSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxFontSize}rem )`;
}
